import api from "./base-api"


const tohStateApiV2 = api.injectEndpoints({
    endpoints: (build) => ({
        getStateV2TOH: build.query({
            query: (data) => {
                return `v2/toh/states/${data.deviceId}/${data.stateId}`
            },
            providesTags: ['UserStates']
        }),
        getStatesByFacebookIdV2TOH: build.query({
            query: (facebookId = "") => `v2/toh/states/facebook/${facebookId}`
        }),
        createStateV2TOH: build.mutation({
            query: (data) => {
                const {userId, vendorId, game, ...body} = data


                return {
                    url: `v2/toh/states/${game}/${userId}?vendorId=${vendorId}`,
                    method: "POST",
                    body: body.body
                }
            },
            invalidatesTags: ['UserStates']
        }),
        clearData: build.mutation({
            query: () => null,
            onQueryStarted: (arg, {dispatch, queryFulfilled}) => {
                dispatch(clearData())
                queryFulfilled(undefined, {data: null})
            },
        }),
        checkRtExist: build.mutation({
            query: (body = {}) => ({
                url: `v2/toh/states/exist`,
                method: "POST",
                body: body
            }),
        }),
        advancedSearchTOH: build.mutation({
            query: (body = {}) => ({
                url: `v2/toh/states/advancedSearch`,
                method: "POST",
                body: body
            }),
        }),
    }),
    overrideExisting: false
})

export const {
    useGetStateV2TOHQuery,
    useGetStatesByFacebookIdV2TOHQuery,
    useCreateStateV2TOHMutation,
    clearData,
    useCheckRtExistMutation,
    useAdvancedSearchTOHMutation
} = tohStateApiV2

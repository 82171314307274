import {
    Box, Button, Card, CardContent, Grid, Paper, TextField
} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import AdvancedSearchTable from "./advancedSearchTable";
import Loading from "../../components/Common/Loading";
import {useSearchParams} from "react-router-dom";
import AdvancedSynchroTable from "./advancedSynchroTable";

export default function AdvancedSearch({useSearchHook, useSynchroHook}) {

    const [showTable, setShowTable] = useState(false);
    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId;
    const vendorId = CommonData.stateId;

    const [searchParams, setSearchParams] = useSearchParams();


    const [advancedSearchParams, setAdvancedSearchParams] = useState({
        stateId: searchParams.get('stateId')?searchParams.get('stateId'):deviceId,
        vendorId: searchParams.get('vendorId')?searchParams.get('vendorId'):vendorId,
        reserveId: searchParams.get('reserveId'),
        facebookId: searchParams.get('facebookId'),
        appleId: searchParams.get('appleId'),
        googleId: searchParams.get('googleId'),
        playGamesId: searchParams.get('playGamesId'),
        gameCenterId: searchParams.get('gameCenterId'),
        globalUserID: searchParams.get('globalUserID'),
    });
    const [advancedSearch, advancedSearchResponse] = useSearchHook();

    useEffect(() => {
        if (advancedSearchResponse && advancedSearchResponse.status === 'fulfilled' ) {
            setShowTable(true);
        }
    }, [advancedSearchResponse])

    useEffect(() => {
        advancedSearch(advancedSearchParams)
    }, [])

    const handleChange = (name) => (e) => {
        let newSearchParams = {...advancedSearchParams};
        newSearchParams[name]=e.target.value;
        setAdvancedSearchParams(newSearchParams);

        let params = {}
        searchParams.forEach((value, key) => {
            params[key] = value;
        });

        setSearchParams({...params, [name]: e.target.value})
    }


    useEffect(() => {
        console.log(CommonData)
    }, [CommonData])

    const search = () => {
        advancedSearch(advancedSearchParams)
    }
    useEffect(() => {
        console.log(CommonData)
    }, [CommonData])

    return <Fragment>

        <Card>
           <CardContent>
               <Grid container spacing={2}>
                   <Grid item sm={3}>
                       <TextField
                           value={advancedSearchParams.stateId}
                           onChange={handleChange('stateId')}
                           fullWidth
                           label={'State ID'}
                       />
                   </Grid>
                   <Grid item sm={3}>
                       <TextField
                           value={advancedSearchParams.vendorId}
                           name={'vendorId'}
                           onChange={handleChange('vendorId')}
                           fullWidth
                           label={'Vendor ID'}
                       />
                   </Grid>

                   <Grid item sm={3}>
                       <TextField
                           value={advancedSearchParams.reserveId}

                           name={'reserveId'}
                           onChange={handleChange('reserveId')}
                           fullWidth
                           label={'Reserve ID'}
                       />
                   </Grid>
                   <Grid item sm={3}>
                       <TextField
                           value={advancedSearchParams.globalUserID}

                           name={'globalUserID'}
                           onChange={handleChange('globalUserID')}
                           fullWidth
                           label={'Global UserID'}
                       />
                   </Grid>
               </Grid>
               <Grid container sx={{mt:1}} spacing={2}>

                   <Grid item sm={2}>
                       <TextField
                           size={"small"}
                           name={'facebookId'}
                           value={advancedSearchParams.facebookId}

                           onChange={handleChange('facebookId')}
                           fullWidth
                           label={'Facebook ID'}
                       />
                   </Grid>
                   <Grid item sm={2}>
                       <TextField
                           size={"small"}

                           name={'appleId'}
                           value={advancedSearchParams.appleId}
                           onChange={handleChange('appleId')}
                           fullWidth
                           label={'Apple ID'}
                       />
                   </Grid>
                   <Grid item sm={2}>
                       <TextField
                           size={"small"}

                           name={'googleId'}
                           value={advancedSearchParams.googleId}

                           onChange={handleChange('googleId')}
                           fullWidth
                           label={'Google ID'}
                       />
                   </Grid>
                   <Grid item sm={2}>
                       <TextField
                           size={"small"}

                           value={advancedSearchParams.playGamesId}
                           name={'playGamesId'}
                           onChange={handleChange('playGamesId')}
                           fullWidth
                           label={'Play Games ID'}
                       />
                   </Grid>
                   <Grid item sm={2}>
                       <TextField
                           size={"small"}

                           name={'gameCenterId'}
                           value={advancedSearchParams.gameCenterId}
                           onChange={handleChange('gameCenterId')}
                           fullWidth
                           label={'Game Center ID'}
                       />
                   </Grid>
               </Grid>
               <Grid container>
                   <Grid>
                       <Box mt={2}>
                           <Button type={'submit'} variant="contained" onClick={search}>search</Button>
                       </Box>
                   </Grid>
               </Grid>
           </CardContent>
        </Card>

        <Loading error={advancedSearchResponse.error} isError={advancedSearchResponse.isError} isLoading={advancedSearchResponse.isLoading}>

            <h1>Result:</h1>
        {showTable && <Paper sx={{marginTop: '10px'}}>
            <AdvancedSearchTable
                advancedSearchResponse={advancedSearchResponse}
            />
            {
                useSynchroHook!=undefined && <AdvancedSynchroTable synchroHook={useSynchroHook} params={advancedSearchParams}></AdvancedSynchroTable>
            }
        </Paper>
        }
        </Loading>
    </Fragment>
}